import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import { Button, Col, Container, Row } from 'react-bootstrap'
import ButtonContact from "../layout/buttoncontact"
import { CloseCase } from "./CloseCase"
import "./aiSecurity.scss"


const AiSecurity = ({ className, previous }) => {

    const { t } = useTranslation("aisecurity")

    return(
        <section className={(className ? className + " " : "") + " aisecurity-case"}>
            <Container fluid>
                <CloseCase referrer={previous} />
                <Row className="back-header">
                    <Row className="firstSection">
                        <div className="max-container-ai">
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Row>
                                    <Col className="tell-project-aisecurity-case" xl={5} lg={5} md={6} sm={12} xs={12}>
                                        <p className="logo">
                                            {t("ai security lab")}
                                        </p>
                                        <p className="whatWeDo">
                                            {t("ml pentesting & security")}
                                        </p>
                                        {/* TODO: btn-cartier ? */}
                                        <a href="#learnMore" rel="noreferrer"><Button className="btn-success btn-cartier">{t("Learn More About AI Security Lab")}</Button></a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Col className="project-presentation" xl={{span:4, offset:6}} lg={{span:4, offset:6}} md={{span:6, offset:5}} sm={12} xs={12}>
                                        <p className="sectionTitle">
                                            {t("BLUE TRAIL AI SECURITY LAB")}
                                        </p>
                                        <p className="presentationText justify">
                                            {t("Created to address growing Al/ML risks and security concerns of Blue Trail Software's customers")}
                                        </p>
                                </Col>
                            </Col>
                        </div>
                    </Row>
                </Row>
                <Row className="back-header" id="learnMore">
                    <Row className="secondSection">
                        <div className="max-container-ai">
                            <Row>
                                <Col className="sectionWrapper" xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <p className="sectionTitle">
                                        {t("AI MUST BE TRUSTED, SAFE AND SECURE")}
                                    </p>
                                    <Row className="cardsSection">
                                        <Col className="cards" xl={4} lg={4} md={6} sm={12} xs={12}>
                                            <div className="backCard card1">{t("Responsible AI")}</div>
                                            <div className="frontCard card2">
                                                <ul>
                                                    <li>{t("Equitable algorithms (fairness, bias)")}</li>
                                                    <li>{t("Ethical Al (no 'off label' uses, etc.)")}</li>
                                                    <li>{t("Compliance, legal and reputational risks")}</li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col className="cards" xl={4} lg={4} md={6} sm={12} xs={12}>
                                            <div className="backCard card3">{t("Responsible AI")}</div>
                                            <div className="frontCard card4">
                                                <ul>
                                                    <li>{t("Have a full understanding of the Al decision-making processes")}</li>
                                                    <li>{t("ML monitoring")}</li>
                                                    <li>{t("ML auditability")}</li>
                                                    <li>{t("ML error analysis")}</li>
                                                    <li>{t("MLOps")}</li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col className="cards" xl={4} lg={4} md={6} sm={12} xs={12}>
                                            <div className="backCard card5">{t("Responsible AI")}</div>
                                            <div className="frontCard card6">
                                                <ul>
                                                    <li>{t("Equitable algorithms (fairness, bias)")}</li>
                                                    <li>{t("Ethical Al (no 'off label' uses, etc.)")}</li>
                                                    <li>{t("Compliance, legal and reputational risks")}</li>
                                                </ul>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Row>
                <Row className="back-header">
                    <Row className="thirdSection">
                        <div className="max-container-ai">
                            <Row>
                                <Col className="sectionWrapper" xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <p className="sectionTitle">
                                    {t("APPLYING INDUSTRY BEST PRACTICES TO ML SECURITY ASSESSMENT")}
                                    </p>
                                    <Row className="threatSection">
                                        <Col className="threatModel" xl={5} lg={5} md={6} sm={12} xs={12}>
                                            <div className="backCardThreat">
                                                <span className="backCardThreatThreatTitle">{t("Threat Model & Security Analysis")}</span>
                                                <div className="frontCardThreat frontCardOne">
                                                    {t("A. Characterize the system")}
                                                </div>
                                                <div className="frontCardThreat frontCardTwo">
                                                    {t("B. Define threat model")}
                                                </div>
                                                <div className="frontCardThreat frontCardThree">
                                                    {t("C. Threat scenarios identification")}
                                                </div>
                                                <div className="frontCardThreat frontCardFour">
                                                    {t("D. Security assesment")}
                                                </div>
                                                <div className="frontCardThreat frontCardFive">
                                                    {t("E. Report")}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="threatExplanation" xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <p className="justify">
                                                {t("A Threat Model & Security Analysis (TMSA) is a key document used in the security industry to define and scope the security evaluation of a system, the target of evaluation:")}
                                            </p>
                                            <ul className="justify">
                                                <li>    {t("Description of the Assets in the system to be protected")}</li>
                                                <li>    {t("Use-cases in which the assets are involved")}</li>
                                                <li>    {t("Security measures in place to protect the assets")}</li>
                                                <li>    {t("Attacker's profile, Threat model and scenarios")}</li>
                                            </ul>
                                            <p className="justify">
                                                {t("The Security Assessment is based upon your selection of threat scenarios defined in the TSA, and leverages security and explainable Al tooling.")}
                                            </p>
                                            <p className="justify">
                                                {t("The Security Assessment Report lists tested Threat Scenarios (TSA), describes attack attempts, and list identified risks and vulnerabilities")}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Row>
                <Row className="back-header">
                    <Row className="fourthSection">
                        <div className="max-container-ai">
                            <Row>
                                <Col className="sectionWrapper" xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <p className="sectionTitle">
                                        {t("TYPICAL ENGAGEMENT")}
                                    </p>
                                    <Row className="engagementSection">
                                        <Row className="bar">
                                            <Col className="phase">
                                                <div className="phaseTitleWrapper">
                                                    <span>{t("TMSA")}</span>
                                                </div>
                                            </Col>
                                            <Col className="phase">
                                                <div className="phaseTitleWrapper">
                                                    <span>{t("Select Threat Scenarios")}</span>
                                                </div>
                                                <i className="bi bi-caret-right-fill" />
                                            </Col>
                                            <Col className="phase">
                                                <div className="phaseTitleWrapper">
                                                    <span>{t("Security Assesment")}</span>
                                                </div>
                                                <i className="bi bi-caret-right-fill" />
                                            </Col>
                                            <Col className="phase">
                                                <div className="phaseTitleWrapper">
                                                    <span>{t("Complete Report")}</span>
                                                </div>
                                                <i className="bi bi-caret-right-fill" />
                                            </Col>
                                        </Row>
                                        <div className="titleWrapper">
                                            <div className="reviewTitle firstTitle">
                                                <span>{t("TMSA Review Meeting")}</span>
                                            </div>
                                            <div className="reviewTitle secondTitle">
                                                <span>{t("Final Review Meeting")}</span>
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Row>
                <Row className="back-header">
                    <Row className="fifthSection">
                        <div className="max-container-ai">
                            <Row>
                                <Col xl={{span:6, offset:6}} lg={{span:6, offset:6}} md={{span:6, offset:6}} sm={12} xs={12}>
                                    <p className="sectionTitle">
                                        {t("ml pentesting & security")}
                                    </p>
                                    <ButtonContact title={t("Try Now AI Security Lab")} children={t("Try Now AI Security Lab")}></ButtonContact>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Row>
            </Container>
        </section >
    )
}

export default AiSecurity